import { LiveReload, Scripts, ScrollRestoration } from '@remix-run/react';

import { ReactNode } from 'react';
import { HeadRootTagEntrycall } from './head.tag.root';
import classNames from 'classnames';
import { getThemeClasses } from '@entrycall/ui-tailwind';
const MODE = 'dark';
const themeClasses = getThemeClasses(MODE);

interface DocumentProps {
  title?: string;
  children: ReactNode;
}

export default function EntryCallHtmlDocument({
  title,
  children,
}: DocumentProps) {
  const classes = classNames(themeClasses.backgroundColor);

  return (
    <html lang="en">
      <HeadRootTagEntrycall title={title} />
      <body className={classes}>
        {children}
        {/* //* Scroll restoration for maintaining scroll position */}
        <ScrollRestoration />
        {/* //* Include Remix scripts */}
        <Scripts />
        {/* //* LiveReload for hot reloading during development */}
        <LiveReload />
      </body>
    </html>
  );
}
