// ErrorBoundaryRootPayment.tsx
import { useEffect } from 'react';
import { API_CONFIG } from '@entrycall/lib-api-server';
import { Links, Meta, Scripts, useRouteError, isRouteErrorResponse, Link } from '@remix-run/react';
import { captureError, logError } from '@entrycall/coralogix';

interface ErrorLayoutProps {
  children: React.ReactNode;
  title: string;
}

interface ErrorLayoutProps {
  children: React.ReactNode;
  title: string;
}

interface ErrorDetails {
  errorId: string;
  timestamp: number;
  url: string;
  userAgent: string;
}

const generateErrorDetails = (): ErrorDetails => ({
  errorId: crypto.randomUUID(),
  timestamp: Date.now(),
  url: window.location.href,
  userAgent: window.navigator.userAgent
});

const handleError = (error: unknown, errorDetails: ErrorDetails) => {
  if (error instanceof Error) {
    captureError(error, errorDetails);
  } else {
    logError('Unexpected error occurred', {
      error: String(error),
      ...errorDetails
    });
  }
};

export function ErrorBoundaryRootPayment() {
  const error = useRouteError();
  const errorDetails = generateErrorDetails();

  // Log error to Coralogix
  useEffect(() => {
    handleError(error, errorDetails);
  }, [error]);

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return (
        <ErrorLayout title={ERROR_MESSAGES.notFound.title}>
          <ErrorContent
            status={ERROR_MESSAGES.notFound.status}
            title={ERROR_MESSAGES.notFound.title}
            description={ERROR_MESSAGES.notFound.description}
          />
        </ErrorLayout>
      );
    }

    // Handle other HTTP errors
    return (
      <ErrorLayout title={`Error ${error.status}`}>
        <ErrorContent
          status={`${error.status} Error`}
          title={error.statusText}
          description={error.data?.message || ERROR_MESSAGES.generic.description}
          errorId={errorDetails.errorId}
        />
      </ErrorLayout>
    );
  }

  return (
    <ErrorLayout title={ERROR_MESSAGES.generic.title}>
      <ErrorContent
        status={ERROR_MESSAGES.generic.status}
        title={ERROR_MESSAGES.generic.title}
        description={ERROR_MESSAGES.generic.description}
        errorId={errorDetails.errorId}
      />
    </ErrorLayout>
  );
}

interface ErrorContentProps {
  status: string;
  title: string;
  description: string;
  errorId?: string;
}

const ErrorContent = ({ status, title, description, errorId }: ErrorContentProps) => (
  <>
    <p className={ERROR_BOUNDARY_STYLES.typography.status}>{status}</p>
    <h1 className={ERROR_BOUNDARY_STYLES.typography.title}>{title}</h1>
    <p className={ERROR_BOUNDARY_STYLES.typography.description}>
      {description}
      {errorId && <strong> (ID: {errorId})</strong>}
    </p>
    <NavigationButtons />
  </>
);

const ErrorLayout = ({ children, title }: ErrorLayoutProps) => (
  <html lang="en" className={ERROR_BOUNDARY_STYLES.container.html}>
    <head>
      <title>{title}</title>
      <Meta />
      <Links />
    </head>
    <body className={ERROR_BOUNDARY_STYLES.container.body}>
      <main className={ERROR_BOUNDARY_STYLES.container.main}>
        <img
          src={ERROR_BOUNDARY_STYLES.images.backgroundUrl}
          alt=""
          className={ERROR_BOUNDARY_STYLES.container.image}
        />
        <div className={ERROR_BOUNDARY_STYLES.container.contentWrapper}>
          {children}
        </div>
      </main>
      <Scripts />
    </body>
  </html>
);

const NavigationButtons = () => (
  <div className={ERROR_BOUNDARY_STYLES.container.buttonContainer}>
    <Link
      to={API_CONFIG.WEBSITE_ROUTES.LOGOUT_ROUTE.path}
      className={ERROR_BOUNDARY_STYLES.typography.link}
    >
      Return to home
    </Link>
    <button
      type="button"
      onClick={() => window.history.back()}
      className={ERROR_BOUNDARY_STYLES.typography.button}
    >
      Go back
    </button>
  </div>
);


export const ERROR_MESSAGES = {
  notFound: {
    status: "404 Error",
    title: "Page Not Found",
    description: "Sorry, we couldn't find the page you're looking for."
  },
  generic: {
    status: "Error",
    title: "We've encountered an error",
    description: "We're very sorry for the inconvenience. Here's an error code you can use to help us solve this issue:"
  }
};

// errorBoundaryConstants.ts
export const ERROR_BOUNDARY_STYLES = {
  container: {
    html: "h-full",
    body: "h-full",
    main: "relative isolate min-h-full",
    image: "absolute inset-0 -z-10 h-full w-full object-cover object-top",
    contentWrapper: "mx-auto max-w-7xl px-6 py-32 text-center sm:py-40 lg:px-8",
    buttonContainer: "mt-10 flex justify-center space-x-4"
  },
  typography: {
    status: "text-base font-semibold leading-8 text-white",
    title: "mt-4 text-3xl font-bold tracking-tight text-white sm:text-5xl",
    description: "mt-4 text-base text-white/70 sm:mt-6",
    link: "text-sm font-semibold leading-7 text-white",
    button: "text-sm font-semibold leading-7 text-white"
  },
  images: {
    backgroundUrl: "https://images.unsplash.com/photo-1545972154-9bb223aac798?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3050&q=80&exp=8&con=-15&sat=-75"
  }
}