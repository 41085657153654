import { Links, Meta } from '@remix-run/react';

interface HeadProps {
  title?: string;
}

const faviconHref = 'assets/favicons/favicon.ico';
const logo192 = 'assets/png/logo192.png';
const favicon32Href = 'assets/icons/favicon-32x32.png';
const favicon16Href = 'assets/icons/favicon-16x16.png';
const appleTouchIconHref = '/assets/logo_files/pngs_svg/1x/asset_2.png';
const entrycallImageHref = '/assets/logo_files/pngs_svg/1x/asset_2.png';
const entrycallUrl = 'app.entrycall.ca';

export function HeadRootTagEntrycall({ title }: HeadProps) {
  return (
    <head>
      {/* Essential Meta Tags */}
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width,initial-scale=1,viewport-fit=cover,user-scalable=yes" />
      {title ? <title>{title}</title> : null}
      <Meta />

      {/* PWA Settings */}
      <meta name="theme-color" content="#1D62CB" />
      <meta name="application-name" content="Entrycall" />
      <meta name="mobile-web-app-capable" content="yes" />

      {/* iOS Specific */}
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="default" />
      <meta name="apple-mobile-web-app-title" content="Entrycall" />
      
      {/* Favicons and Icons */}
      <link rel="icon" type="image/png" sizes="32x32" href={favicon32Href} />
      <link rel="icon" type="image/png" sizes="16x16" href={favicon16Href} />
      <link rel="apple-touch-icon" sizes="180x180" href={appleTouchIconHref} />
      
      {/* SEO Tags */}
      <meta name="description" content="Simple. Secure. Reliable EntryPhones." />
      <meta name="keywords" content="house management, intercom, home automation, security, smart home, property management, communication" />
      
      {/* Open Graph */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Entrycall" />
      <meta property="og:description" content="Simple. Secure. Reliable EntryPhones." />
      <meta property="og:url" content={entrycallUrl} />
      <meta property="og:image" content={entrycallImageHref} />
      
      <Links />
    </head>
  );
}