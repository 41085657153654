/// <reference path="../../../../../../types/redux-persist.d.ts" />
import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { rootReducer } from './reducers/rootReducer.reducer';
import createWebStorage from 'redux-persist/lib/storage/createWebStorage.js';

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

// Function tyo check if it's SSR
export function createPersistStore() {
  const isServer = typeof window === 'undefined';
  if (isServer) {
    return {
      getItem() {
        return Promise.resolve(null);
      },
      setItem() {
        return Promise.resolve();
      },
      removeItem() {
        return Promise.resolve();
      },
    };
  }
  return createWebStorage('local');
}

const storage =
  typeof window !== 'undefined'
    ? createWebStorage('local')
    : createPersistStore();

const persistConfig = {
  key: 'root',
  version: 1,
  storage: storage,
  blacklist: ['auth'],
};

//* Set up persisted and combined reducers
const persistedReducer = persistReducer(persistConfig, rootReducer);

//* Create the store with the persisted reducers and middleware/enhancers
const store = configureStore({
  devTools: process.env?.['NODE_ENV'] !== 'production' || true,
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export type RootState = ReturnType<typeof rootReducer>;
export const persistor = persistStore(store);
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export { store };
