import type { LinksFunction } from "@remix-run/node";

interface StylesheetProps {
  href: string;
}

interface IconProps {
  href: string;
  sizes?: string;
}

interface ThemeProps {
  primaryColor: string;
  secondaryColor: string;
}

export type LinkChild = StylesheetProps | IconProps | ThemeProps;

export const linksEntryCall = (children: LinkChild[]): ReturnType<LinksFunction> => {
  return children.map((child): ReturnType<LinksFunction>[number] => {
    if ('sizes' in child) {
      return {
        rel: "icon",
        type: "image/png",
        sizes: child.sizes,
        href: child.href
      };
    } else {
      return {
        rel: 'stylesheet',
        href: child.href
      };
    }
  });
};

