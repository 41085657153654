import { type MetaFunction } from '@remix-run/node';

// Define meta tags for the application
export const metaData: MetaFunction = () => [
  {
    title: 'Entrycall',
    description:
      'Efficient house management and secure intercom systems by Entrycall.',
    keywords:
      'house management, intercom, home automation, security, smart home, property management, communication',
    'og:title': 'Entrycall - House Management and Intercoms',
    'og:description':
      'Efficient house management and secure intercom systems by Entrycall.',
    'og:type': 'website',
    'twitter:card': 'summary_large_image',
    'twitter:creator': '@YourTwitterHandle',
    'twitter:title': 'Entrycall - House Management and Intercoms',
    'twitter:description':
      'Efficient house management and secure intercom systems by Entrycall.',
  },
];
