// Import necessary dependencies
import React from 'react';
import { useLoaderData } from '@remix-run/react';
import { NoPermissionMessage } from '../components/no-permission-message';
import type { PropertyPermission } from '@entrycall/types-project';

// Define the structure of the loader data containing property permissions
interface PermissionLoaderData {
  propertyPermission: PropertyPermission;
}

// Define options for the withPermission HOC, allowing a custom fallback component
interface WithPermissionOptions {
  fallback?: React.ReactNode;
}

// Define the withPermission Higher-Order Component
export const withPermission = (
  // Accept a permission key to check, using keyof PropertyPermission for type safety
  permissionKey: keyof PropertyPermission,
  // Accept options with default empty object
  options: WithPermissionOptions = {},
) => {
  // Return a function that takes a component and returns a new component
  return <P extends object>(WrappedComponent: React.ComponentType<P>) => {
    // Define the new component that will check permissions
    const WithPermissionComponent: React.FC<P> = (props) => {
      // Get property permissions from the loader data
      const { propertyPermission } = useLoaderData<PermissionLoaderData>();
      // Check if the specific permission is granted, default to false if not found
      const isPermissioned = propertyPermission[permissionKey] ?? false;

      // If permission is granted, render the wrapped component with its props
      if (isPermissioned) {
        return <WrappedComponent {...props} />;
      } else {
        // If permission is not granted, render the fallback or default NoPermissionMessage
        return options.fallback || <NoPermissionMessage />;
      }
    };

    // Set a display name for the new component for easier debugging
    WithPermissionComponent.displayName = `WithPermission(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;

    // Return the new permission-checking component
    return WithPermissionComponent;
  };
};
