// lib\shared\redux\src\lib\store\slices\payment.slice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../configureStore';
import { setStripeTotalToPay } from './strapi.slice';

const calculatePriceBasedOnTime = (
  timePriceConfigs: TimePriceConfig[],
): number => {
  const currentHour = new Date().getHours();

  for (const config of timePriceConfigs) {
    if (currentHour >= config.startHour && currentHour < config.endHour) {
      return config.price;
    }
  }

  // Default price if no range matches
  return 0;
};

interface TimePriceConfig {
  startHour: number;
  endHour: number;
  price: number;
}

export interface IUserPaymentRouteSlice {
  license_plate: string;
  parking_location: string;
  location_rates: TimePriceConfig[];
  how_many_hours: number;
  how_many_minutes: number;
  discount_voucher_code: boolean;
  total_to_pay_before_hst: number;
  hst: number;
  total_to_pay: number;
}

export interface IPageNavigationSlice {
  page: number;
  formTitlesArr: string[];
}

const userPaymentInitialState: IUserPaymentRouteSlice = {
  license_plate: '',
  parking_location: '',
  location_rates: [
    { startHour: 6, endHour: 16, price: 2 },
    { startHour: 16, endHour: 24, price: 1 },
    { startHour: 0, endHour: 6, price: 1 },
  ],
  how_many_hours: 0,
  how_many_minutes: 0,
  discount_voucher_code: false,
  total_to_pay_before_hst: 0,
  hst: 1.13,
  total_to_pay: 0,
};

const pageNavigationInitialState: IPageNavigationSlice = {
  page: 0,
  formTitlesArr: ['License plate', 'Times', 'Payment', 'Done'],
};

const initialStateForPayment = {
  paymentForParkingByUser: userPaymentInitialState,
  pageNavigation: pageNavigationInitialState,
  loading: false,
  error: null,
};

export const paymentSlice = createSlice({
  name: 'paymentSlice',
  initialState: initialStateForPayment,
  reducers: {
    setLicensePlate: (state, action: PayloadAction<string>) => {
      state.paymentForParkingByUser.license_plate = action.payload;
    },
    setHowManyHours: (state, action: PayloadAction<number>) => {
      state.paymentForParkingByUser.how_many_hours = action.payload;
      updateTotalToPay(state.paymentForParkingByUser);
    },
    setHowManyMinutes: (state, action: PayloadAction<number>) => {
      state.paymentForParkingByUser.how_many_minutes = action.payload;
      updateTotalToPay(state.paymentForParkingByUser);
    },
    setValidationCode: (state, action: PayloadAction<number>) => {
      state.paymentForParkingByUser.discount_voucher_code =
        action.payload === 1;
      updateTotalToPay(state.paymentForParkingByUser);
    },
    setOnClickToPay: (state, action: PayloadAction<number>) => {},
    setPage: (state, action: PayloadAction<number>) => {
      if (!state.pageNavigation) {
        state.pageNavigation = { ...pageNavigationInitialState };
      }
      state.pageNavigation.page = action.payload;
    },
  },
});

export function updateTotalToPay(state: IUserPaymentRouteSlice) {
  const taxHST = state.hst;
  const discountFactor = state.discount_voucher_code ? 0 : 1;

  const pricePerTime = calculatePriceBasedOnTime(state.location_rates);
  const totalPakingTime = state.how_many_hours + state.how_many_minutes / 60;
  const priceWithoutDiscounts = pricePerTime * totalPakingTime;

  state.total_to_pay_before_hst = priceWithoutDiscounts * discountFactor;
  state.total_to_pay = state.total_to_pay_before_hst * taxHST;
}

// Stripe update
export const updateStripeTotalToPay = (): AppThunk => (dispatch, getState) => {
  const totalToPay = selectTotalToPay(getState());
  dispatch(setStripeTotalToPay(totalToPay));
};

export const {
  setLicensePlate,
  setHowManyHours,
  setHowManyMinutes,
  setValidationCode,
  setPage,
} = paymentSlice.actions;

export const selectLicensePlate = (state: RootState) =>
  state.payment.paymentForParkingByUser.license_plate;
export const selectHowManyHours = (state: RootState) =>
  state.payment.paymentForParkingByUser.how_many_hours;
export const selectHowManyMinutes = (state: RootState) =>
  state.payment.paymentForParkingByUser.how_many_minutes;
export const selectTotalToPay = (state: RootState) =>
  state.payment.paymentForParkingByUser.total_to_pay;
export const selectValidationCode = (state: RootState) =>
  state.payment.paymentForParkingByUser.discount_voucher_code ? 0 : 1;
export const selectTotalToPayBeforeHST = (state: RootState) =>
  state.payment.paymentForParkingByUser.total_to_pay_before_hst;
export const selectHST = (state: RootState) =>
  state.payment.paymentForParkingByUser.hst;

// Form pagination
export const selectPage = (state: RootState) =>
  state.payment.pageNavigation?.page ?? 0;


export const selectFormTitlesArr = (state: RootState) =>
  state.payment.pageNavigation.formTitlesArr;

export default paymentSlice.reducer;
