// strapiSlice.js

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../configureStore';

export interface IStrapiSlice {
  isSubmitted: boolean;
  stripe_total_to_pay: number;
  stripe_currency: string;
}

const userPaymentInitialFormState: IStrapiSlice = {
  isSubmitted: false,
  stripe_total_to_pay: 0,
  stripe_currency: 'usd',
};

const initialState = {
  paymentByUser: userPaymentInitialFormState,
  loading: false,
  error: null,
};

export const strapiSlice = createSlice({
  name: 'starpi',
  initialState,
  reducers: {
    submitForm: (state) => {
      state.paymentByUser.isSubmitted = true;
    },
    resetForm: (state) => {
      state.paymentByUser.isSubmitted = false;
    },
    setStripeTotalToPay: (state, action: PayloadAction<number>) => {
      state.paymentByUser.stripe_total_to_pay = action.payload;
    },
  },
});

export const { submitForm, resetForm, setStripeTotalToPay } = strapiSlice.actions;


//* total amount to pay - used in paymentSlice.ts
export const selectStripeTotalToPay = (state: RootState) =>
  state.strapi.paymentByUser.stripe_total_to_pay;

//* stapi form submition
export const selectStrapiSubmitForm = (state: RootState) =>
  state.strapi.paymentByUser.isSubmitted;

export default strapiSlice.reducer;
