// lib\shared\redux\src\lib\store\reducers\rootReducer.reducer.ts

import { combineReducers } from '@reduxjs/toolkit';
import paymentReducer, {
  type IUserPaymentRouteSlice,
} from '../slices/payment.slice';
import strapiReducer, { type IStrapiSlice } from '../slices/strapi.slice';

export interface RootState {
  payment: IUserPaymentRouteSlice;
  strapi: IStrapiSlice;
}

export const rootReducer = combineReducers({
  payment: paymentReducer,
  strapi: strapiReducer,
});


