import { baseStyles } from '@entrycall/ui-tailwind';
import classNames from 'classnames';
import React from 'react';

const MESSAGE_CONTACT_ADMIN = 'Please contact your administrator for access.';

export const NoPermissionMessage: React.FC = () => (
  <div className="flex items-center justify-center h-full w-full">
    <div className={classNames(
      'flex flex-col items-center p-8',
      baseStyles.borderRadius.large.rounded.default,
      'text-center'
    )}>
      <p className="text-lg mb-4">{MESSAGE_CONTACT_ADMIN}</p>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="black"
        className="w-10 h-10"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
        />
      </svg>
    </div>
  </div>
);
