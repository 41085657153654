// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/root.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/root.tsx");
}
// REMIX HMR END

// Extend the global interface

import { captureError, initCoralogix, logInfo } from '@entrycall/coralogix';
import twStyles from './styles/tailwind.css';
import { Provider } from 'react-redux';
import { store } from '@entrycall/redux';
import serialize from 'serialize-javascript';
import { json, LiveReload, Outlet, Scripts, ScrollRestoration, useLoaderData } from '@remix-run/react';
import { useEffect, useMemo } from 'react';
import { AuthProvider } from '@descope/react-sdk';
import { ErrorBoundaryRootPayment, EntryCallHtmlDocument, metaData, linksEntryCall } from '@entrycall/route-root';
import { sharedDOMPurify } from '@entrycall/utility-dompurify';
import toastStyles from "react-toastify/dist/ReactToastify.css";
const getTrustedHTMLPolicy = () => {
  if (typeof window === 'undefined' || !window.trustedTypes) return undefined;
  try {
    return window.trustedTypes.createPolicy('entrycallPolicy', {
      createHTML: input => sharedDOMPurify.sanitize(input),
      createScript: () => {
        throw new Error('Scripts not allowed');
      },
      createScriptURL: () => {
        throw new Error('Script URLs not allowed');
      }
    });
  } catch (error) {
    const factory = window.trustedTypes;
    const existingPolicy = factory.getPolicy?.('entrycallPolicy');
    if (existingPolicy) return existingPolicy;
    captureError(error instanceof Error ? error : new Error(String(error)), {
      context: 'Trusted Types Policy creation'
    });
    return undefined;
  }
};
const DESCOPE_PROJECT_ID = 'P2gbxFS9y61jkSPQ8us5qtixoBoL';

// Define the children array
const children = [{
  href: twStyles.toString()
}, {
  href: toastStyles.toString()
}, {
  href: '/icons/favicon-32x32.png',
  sizes: '32x32'
}, {
  href: '/icons/favicon-16x16.png',
  sizes: '16x16'
}, {
  href: '/assets/png/logo192.png',
  sizes: '192x192'
}, {
  href: '/assets/png/logo512.png',
  sizes: '512x512'
}, {
  primaryColor: '#ff0000',
  secondaryColor: '#00ff00'
}];

// Use the linksEntryCall function with the children array
export const links = () => linksEntryCall(children);
export const loader = async () => {
  return json({
    ENV: {
      STRIPE_PUBLIC_KEY: process.env.NX_STRIPE_PUBLISHABLE_KEY,
      CORALOGIX_PUBLIC_KEY: process.env.CORALOGIX_PUBLIC_KEY || 'cxtp_VA7Kh6c1gxZm8i4aTw32RRHY7ynMUF',
      CORALOGIX_APPLICATION: process.env.CORALOGIX_APPLICATION || 'entrycall-portal',
      CORALOGIX_VERSION: process.env.CORALOGIX_VERSION || '2.0.1',
      CORALOGIX_DOMAIN: process.env.CORALOGIX_DOMAIN || 'EU1'
    }
  });
};
export const meta = metaData;
export const ErrorBoundary = ErrorBoundaryRootPayment;
export default function App() {
  _s();
  const loaderData = useLoaderData();
  const envData = loaderData?.ENV ?? {};

  // Ensure envData does not contain sensitive information
  // If it does, remove or obfuscate it before serialization
  const serializedEnvData = useMemo(() => serialize(envData, {
    isJSON: true
  }), [envData]);
  const scriptContent = `window.ENV = ${serializedEnvData};`;
  useEffect(() => {
    const themeLink = document.querySelector('link[href="/styles/theme.css"]');
    if (themeLink) {
      const primary = themeLink.getAttribute('data-primary');
      const secondary = themeLink.getAttribute('data-secondary');
      if (primary) document.documentElement.style.setProperty('--primary-color', primary);
      if (secondary) document.documentElement.style.setProperty('--secondary-color', secondary);
    }
  }, []);
  useEffect(() => {
    const initApp = async () => {
      const coralogixConfig = {
        public_key: envData.CORALOGIX_PUBLIC_KEY,
        application: envData.CORALOGIX_APPLICATION,
        version: envData.CORALOGIX_VERSION,
        coralogixDomain: envData.CORALOGIX_DOMAIN
      };
      try {
        await initCoralogix(coralogixConfig.public_key, coralogixConfig.application, coralogixConfig.version, coralogixConfig.coralogixDomain).catch(error => {
          throw new Error(`Coralogix initialization failed: ${error.message}`);
        });
        await logInfo('Application initialized', {
          application: coralogixConfig.application,
          version: coralogixConfig.version
        });
      } catch (error) {
        captureError(error instanceof Error ? error : new Error(String(error)), {
          context: 'Application initialization',
          config: coralogixConfig
        });
      }
    };
    if (envData.CORALOGIX_PUBLIC_KEY) {
      initApp();
    }
  }, [envData]);
  return <EntryCallHtmlDocument>
      <Provider store={store}>
        <AuthProvider projectId={DESCOPE_PROJECT_ID}>
          <Outlet />
        </AuthProvider>
        <ScrollRestoration />
        <SafeScript content={scriptContent} />
        <Scripts />
        <LiveReload />
      </Provider>
    </EntryCallHtmlDocument>;
}
_s(App, "+vzeLIt4LjL9F2LtnvEnFP+Lnoc=", false, function () {
  return [useLoaderData];
});
_c = App;
function SafeScript({
  content
}) {
  const trustedTypesPolicy = getTrustedHTMLPolicy();
  let trustedContent = content;
  if (trustedTypesPolicy) {
    try {
      trustedContent = trustedTypesPolicy.createHTML(content);
    } catch (error) {
      if (error instanceof Error) {
        captureError(error, {
          additional: 'context',
          url: window.location.href
        });
      }
      console.error('Failed to create trusted HTML:', error);
    }
  }
  return <script dangerouslySetInnerHTML={{
    __html: trustedContent
  }} />;
}
_c2 = SafeScript;
var _c, _c2;
$RefreshReg$(_c, "App");
$RefreshReg$(_c2, "SafeScript");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;